import {isSameDay} from "date-fns"
export function getIndexOfTodaysDate(arrayOfObjects) {
    // Get today's date
    const todayDate = new Date();
  
    let myValue = -1; // Initialize with a default value indicating not found
  
    // Iterate through the main array
    for (let i = 0; i < arrayOfObjects.length; i++) {
      const shiftsArray = arrayOfObjects[i].shifts;
  
      // Check if the shifts array exists and has more than one element
      if (shiftsArray && shiftsArray.length > 0) {
        // Find the index of the first shifts array containing today's date
        for (let j = 0; j < shiftsArray.length; j++) {
          const item = shiftsArray[j];
          const checkDate = new Date(item.date);
          // Compare only the date parts
          if (isSameDay(checkDate, todayDate)) {
            myValue = i;
            break; // No need to continue checking once found
          }
        }
      }
    }

    return myValue;
  }