import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import HeaderComponent from "../HeaderComponent";
import DynamicViewComponent from "../DynamicViewComponent";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDataContext } from "../../context/DataProvider";
import useAuth from "../../hooks/useAuth";
import { BASE_URL } from "../../api/axios";
import StyledRadioButton from "../StyledRadioButton";
import { useSettingsContext } from "../../context/SettingsProvider";
import { generateSelectMenu } from "../../utility/generateSelectMenu";
import { getIndexOfTodaysDate } from "../../utility/getIndexOfTodaysDate";
export default function HomePageComponent({ url, header }) {
  const departmentUrl = url || "";
  const auth = useAuth();
  const { setSettingsList, setSelectOptions } = useSettingsContext();
  const [viewStart, setViewStart] = useState(0);
  const [viewEnd, setViewEnd] = useState(1);
  const [viewLength, setViewLength] = useState(12);
  const [interval, setInterval] = useState(1);
  const axiosPrivate = useAxiosPrivate();
  const { dataList, setDataList } = useDataContext();
  const [update, setUpdate] = useState();
  const [sseOn, setSSEOn] = useState(
    localStorage.getItem("eventStream") || false
  );
  const [restartEventStream, setRestartEventStream] = useState(false);
  const applyTextSizes = () => {
    return interval === 6 ? "1em" : interval === 3 ? "1em" : "1em";
  };
  const weekIncludesTodaysDate = dataList
    ? getIndexOfTodaysDate(dataList[0].shifts) !== -1
      ? getIndexOfTodaysDate(dataList[0].shifts)
      : 0
    : 0;

  useEffect(() => {
    if (viewEnd - viewStart === 1) {
      setViewStart(weekIncludesTodaysDate);
      setViewEnd(1 + weekIncludesTodaysDate);
    }
    if (viewEnd - viewStart === 2 && weekIncludesTodaysDate >= 4) {
      setViewStart(4);
      setViewEnd(6);
    } else if (viewEnd - viewStart === 2) {
      setViewStart(weekIncludesTodaysDate);
      setViewEnd(weekIncludesTodaysDate + 2);
    }

    if (viewEnd - viewStart === 3 && weekIncludesTodaysDate > 3) {
      setViewStart(3);
      setViewEnd(6);
    } else if (viewEnd - viewStart === 3) {
      setViewStart(weekIncludesTodaysDate);
      setViewEnd(weekIncludesTodaysDate + 3);
    }
  }, [weekIncludesTodaysDate]);
  function nextPage(e) {
    e.preventDefault();
    if (viewEnd + interval <= 6) {
      let startValue = viewStart + interval;
      let endValue = viewEnd + interval;
      setViewStart(startValue);
      setViewEnd(endValue);
    }
  }
  function prevPage(e) {
    if (viewStart - interval >= 0) {
      e.preventDefault();
      let startValue = viewStart - interval;
      let endValue = viewEnd - interval;
      setViewStart(startValue);
      setViewEnd(endValue);
    }
  }
  function getDateComponent() {
    const dateArray = dataList[0];
    dateArray.shifts.forEach((week) => {
      week.shifts.forEach((element) => {
        const date = new Date(element.date);
        element.shift = date.getDate();
      });
    });

    return (
      <section>
        <DynamicViewComponent
          name={""}
          includeMonth={true}
          disableEdit={true}
          sixWeekData={dateArray.shifts}
          viewStart={viewStart}
          viewEnd={viewEnd}
          viewLength={viewLength}
          containerHeight={"25px"}
          changeTextSize={() => {
            return interval === 6 ? "1em" : interval === 3 ? ".75em" : "1em";
          }}
        ></DynamicViewComponent>
        <DynamicViewComponent
          name={"NAMN"}
          disableEdit={true}
          sixWeekData={dateArray.shifts}
          useData={"weekDay"}
          viewStart={viewStart}
          viewEnd={viewEnd}
          viewLength={viewLength}
          containerHeight={"25px"}
          changeTextSize={() => {
            return applyTextSizes();
          }}
        ></DynamicViewComponent>
      </section>
    );
  }
  function AddDateHeaders(index) {
    const indexArray = [0, 8, 21, 33, 45];
    if (indexArray.includes(index)) {
      return (
        <Fragment key={"subheaders" + index}>
          {getDateComponent(index)}
        </Fragment>
      );
    }
  }

  const fetchSettingsData = async () => {
    let isMounted = true;
    const controller = new AbortController();

    try {
      const response = await axiosPrivate.get(`${departmentUrl}/settings`, {
        signal: controller.signal,
      });
      isMounted && setSettingsList(response.data);
      setSelectOptions(generateSelectMenu(response.data));
    } catch (err) {
      console.error(err);
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchSettingsData();
  }, []);

  const fetchEmployeeData = async () => {
    let isMounted = true;
    const controller = new AbortController();

    try {
      const response = await axiosPrivate.get(`${departmentUrl}/api`, {
        signal: controller.signal,
      });
      isMounted && setDataList(response.data);
    } catch (err) {
      console.error(err);
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  useEffect(() => {
    const reloadWebpage = () => {
      window.location.reload();
    };
    setTimeout(reloadWebpage, 60000 * 60 * 24 + 10);
  }, []);

  useEffect(() => {
    const ctrl = new AbortController();
    let mount = true;
    let events;
    let timer;
    let pingCtrl = new AbortController();
    const pingServer = () => {
      const result = axiosPrivate
        .get("/ping", { withCredentials: true, signal: pingCtrl.signal })
        .catch((err) => {
          console.log(err);
          console.log("failed ping");
          pingCtrl.abort();
        });
      console.log(result);
    };
    let createEvents = async (events, timer) => {
      if (events) {
        ctrl.abort();
      }
      events = await fetchEventSource(`${BASE_URL}/${departmentUrl}/sse`, {
        method: "GET",
        headers: {
          Accept: "text/event-stream",
          Authorization: `Bearer ${auth.auth.accessToken}`,
        },
        credentials: "include",
        heartbeatTimeout: 120000,

        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log("Connection made ", res);
          } else if (res.status === 403) {
            console.log("Authorization expired!");
            ctrl.abort();
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log("Client side error ", res);
          }
        },
        onmessage(event) {
          if (mount && event.event !== "ping" && event.data !== "") {
            console.log(event.event === "ping");
            console.log(event.data === "");
            console.log(event);
            const parsedData = JSON.parse(event.data);
            setUpdate(parsedData);
          }
          if (event.event === "ping") {
            console.log("Server ping!");
          }
          console.log(event);
        },
        onclose() {
          ctrl.abort();
          console.log("Connection closed by the server");
          setRestartEventStream(!restartEventStream);
        },
        onerror(err) {
          console.log("There was an error from server", err);
          ctrl.abort();
        },
      });
    };

    if (sseOn) {
      createEvents(events, timer);
      pingServer();
    }
    return () => {
      mount = false;
      clearTimeout(timer);
      ctrl.abort();
    };
  }, [sseOn, restartEventStream]);

  useEffect(() => {
    if (update && dataList) {
      const documentIndex = dataList.findIndex(
        (item) => item?._id === update?.documentKey?._id
      );

      const weekIndex = Object.keys(Object.entries(update)[1][1])[0];
      const dataListWeekCopy =
        dataList[documentIndex]?.shifts[weekIndex.slice(-1)]?.shifts;
      const updateWeekCopy = update?.updatedFields[weekIndex]?.shifts;
      const result = dataListWeekCopy?.filter(
        (item, index) => item.shift !== updateWeekCopy[index].shift
      );
      // console.log(dataListWeekCopy, updateWeekCopy);
      const dayIndex = dataListWeekCopy?.findIndex(
        (item) => item?._id === result[0]?._id
      );

      const updatedDataList = [...dataList];
      if (dayIndex !== -1) {
        updatedDataList[documentIndex].shifts[weekIndex.slice(-1)].shifts[
          dayIndex
        ].shift = updateWeekCopy[dayIndex].shift;
        setDataList(updatedDataList);
      }
    }
  }, [update]);

  let currentSubHeader = "";
  function checkSubHeaders(currentSubHeader, department) {
    return currentSubHeader !== department;
  }

  return (
    <Container>
      <HeaderComponent
        header={header}
        url={departmentUrl}
        eventstream={sseOn}
        setEventStream={setSSEOn}
      ></HeaderComponent>
      <ViewButtonCountainer>
        <ViewButtonContent>
          <NextButton onClick={prevPage}>Prev</NextButton>
          <StyledRadioButton
            setStart={setViewStart}
            setEnd={setViewEnd}
            setLength={setViewLength}
            setInt={setInterval}
          ></StyledRadioButton>
          <NextButton onClick={nextPage}>Next</NextButton>
        </ViewButtonContent>
      </ViewButtonCountainer>
      {dataList &&
        dataList.map((item, index) => {
          if (index === 0) {
            return AddDateHeaders(index);
          }
          if (checkSubHeaders(currentSubHeader, item.department)) {
            currentSubHeader = item.department;
            return (
              <Fragment key={index + 1000}>
                <SectionHeader key={item.department}>
                  {item.department}
                </SectionHeader>
                {AddDateHeaders(index)}
                <DynamicViewComponent
                  name={item.name}
                  key={item.name + index}
                  sixWeekData={item.shifts}
                  viewStart={viewStart}
                  viewEnd={viewEnd}
                  viewLength={viewLength}
                  containerHeight={"30px"}
                  changeTextSize={() => {
                    return applyTextSizes();
                  }}
                  url={departmentUrl}
                ></DynamicViewComponent>
              </Fragment>
            );
          }
          return (
            <DynamicViewComponent
              name={item.name}
              key={item.name + index}
              sixWeekData={item.shifts}
              viewStart={viewStart}
              viewEnd={viewEnd}
              viewLength={viewLength}
              containerHeight={"30px"}
              changeTextSize={() => {
                return applyTextSizes();
              }}
              url={departmentUrl}
            ></DynamicViewComponent>
          );
        })}
    </Container>
  );
}

const Container = styled.div`
  background-color: #666e79;
  width: 100%;
`;
const ViewButtonContent = styled.div`
  display: flex;
  grid-column-start: 2;
  justify-content: center;
  button:hover {
    background-color: green;
    color: white;
    font-size: 1.2em;
  }
`;
const SectionHeader = styled.div`
  //margin-left: 110px;
  @media (max-width: 700px) {
    font-size: 1em;
  }
  text-align: center;
  font-size: 1.5em;
  font-weight: 700;
  color: whitesmoke;
`;

const NextButton = styled.button`
  font-size: 1.2em;
  font-weight: 600;
  min-width: 110px;
  height: 30px;
  @media (min-width: 400px) {
    border: solid 2px;
    width: 10%;
  }
`;
const ViewButtonCountainer = styled.div`
  display: grid;
  margin-left: 124px;
  grid-template-columns: 3fr 6fr 3fr;
  justify-content: center;
  width: auto;
  /* margin: 10px;
  margin-left: 126.5px; */
  align-items: center;
  padding: 10px;
  @media (max-width: 700px) {
    margin: 5px 5px;
    button {
      width: 100%;
    }
  }
`;

export function getShiftOptions(settings) {
  const tempArray = [];
  settings.forEach((item, index) => {
    item.shift?.forEach((subItem) => {
      tempArray.push(subItem);
    });
  });
  return tempArray;
}
export function getSelectMenuOptions(settings) {
  const tempArray = [];
  settings.forEach((item, index) => {
    let category = item.category || undefined;
    item.shifts?.forEach((subItem) => {
      tempArray.push(category ? subItem.shift + `#${category}` : subItem.shift);
    });
  });
  return tempArray;
}
